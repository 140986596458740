import { builder } from '@magalu/mixer-structure';
import deviceTypeFromUserAgent from '../utils/deviceTypeFromUserAgent';
import isGooglebotFromUserAgent from '../utils/isGooglebotFromUserAgent';
import logger from '../utils/logger';

const useStructure = async ctx => {
  try {
    const { 'user-agent': userAgent, 'mixer-theme-id': themeSuffix } =
      ctx?.req?.headers || {};
    const { resolvedUrl: uri, params, query, siteId, routeId } = ctx;
    const device = deviceTypeFromUserAgent(userAgent);
    const isGooglebot = isGooglebotFromUserAgent(userAgent);
    const ssr = !ctx.req?.url?.includes('_next/data'); // Ex: on router.push
    const { resolvedUrl = null } = ctx;

    const structure = await builder({
      params,
      query,
      routeId,
      siteId,
      themeSuffix,
      uri,
    });

    return { ...structure, device, isGooglebot, resolvedUrl, ssr };
  } catch (e) {
    logger.error('[useStructure] - cannot get structure', e);
    return null;
  }
};

export default useStructure;
