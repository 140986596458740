import get from 'lodash/get';
import { PLACEHOLDER_REPLACE_REGEX } from '../../constants';
export var parsePlaceholder = function parsePlaceholder(placeholders, dataString, source) {
  return placeholders ? placeholders.reduce(function (acc, placeholder) {
    var value = get(source, placeholder.replace(PLACEHOLDER_REPLACE_REGEX, ''), undefined);
    var isFullReplacement = placeholder === dataString;
    if (value !== undefined) {
      return isFullReplacement ? value : acc.replace(placeholder, value);
    }
    return acc;
  }, dataString) : dataString;
};
export default parsePlaceholder;